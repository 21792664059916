import { PureComponent } from 'react';
import { BarChart, Bar, LabelList } from 'recharts';
import { CustomTypography } from './ChartStyles';
import { Divider } from '@mui/material';

const data = [
    {
        value: 45.1,
        displayValue: '45.1M',
        fill: "#B2DFDB"
    },
    {
        value: 92,
        displayValue: '92M',
        fill: '#FFF9C4'
    },
    {
        value: 24.2,
        displayValue: '24.2M',
        fill: "#D1C4E9"
    },
    {
        value: 45.1,
        displayValue: '45.1M',
        fill: '#BBDEFB'
    },
];

export default class CustomBarChart extends PureComponent {

    render() {
        return (
            <>
                <CustomTypography variant="h5">Line Chart</CustomTypography>
                <Divider />
                <BarChart data={data} width={800} height={350}
                    style={{ width: '100%', height: '100%', borderRadius: '10px' }}>
                    <Bar dataKey="value" fill="fill">
                        <LabelList dataKey="displayValue" position="inside" style={{ fill: 'black', fontSize: 40 }} />
                    </Bar>
                </BarChart>
            </>
        );
    }
}
