import theme from "../src/theme";
import "./App.css";
import { Snackbar, ThemeProvider } from "@mui/material";
import { FilterContextProvider } from "./context/plaid-context";
// import { ChatComponent } from './components/Chat/Chat';
// import { AppContainer, FlexLeft, FlexRight } from './App.styles';
import { Navbar } from "./components/navbar/navbar";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { router } from "./router/Router";
import { RouterProvider } from "react-router-dom";
import { useUserContext } from "./context/user-context";
import { GoogleLoginWrapper } from "./components/Login/GoogleLogin";
import { useAuth } from "./context/AuthContext";
import { useEffect, useState } from "react";
import { USER_NOT_IN_WHITELIST } from "./types/constanst";
import Loader from "./components/generics/Loader";

function App() {
  const { isWhitelisted, loading } = useAuth();
  const { user } = useUserContext();

  const [userWhitelisted, setUserWhitelisted] = useState<boolean | null>(null);

  useEffect(() => {
    if (isWhitelisted !== null) {
      setUserWhitelisted(isWhitelisted);
    }
  }, [isWhitelisted]);

  return (
    <>
      {user && !loading && isWhitelisted ? (
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <FilterContextProvider>
              <RouterProvider router={router} />
            </FilterContextProvider>
          </ThemeProvider>
        </I18nextProvider>
      ) : user && loading ? (
        <Loader />
      ) : (
        <>
          <GoogleLoginWrapper />
          {userWhitelisted === false && (
            <Snackbar open={true} message={USER_NOT_IN_WHITELIST} />
          )}
        </>
      )}
    </>
  );
}

export default App;
