import { PureComponent } from 'react';
import { Tooltip, AreaChart, Area, ResponsiveContainer } from 'recharts';

const data = [
    {
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export default class SingleLineChart extends PureComponent {
    render() {
        return (
            <>
                <ResponsiveContainer width="49%" height="20%" style={{ marginTop: 20, paddingBottom: 10, borderRadius: 10, backgroundColor: 'white' }}>
                    <AreaChart
                        style={{ borderRadius: 10, marginLeft: 15, paddingBottom: 10 }}
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <Tooltip />
                        <Area type="monotone" dataKey="amt" fill="#8884d8" />
                    </AreaChart>
                </ResponsiveContainer>
            </>
        );
    }
}