import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ForumIcon from "@mui/icons-material/Forum";
import theme from "../../theme";
import { UploadFile } from "./UPloadFIle";
import {
  LeftGroup,
  NavBarContainer,
  RightGroup,
  Search,
  SearchIconWrapper,
  StyledIconButton,
  StyledInputBase,
} from "./Navbar.styles";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/user-context";

export const ADAPT_BLUE = "#06AEAE";

export const Navbar = () => {
  const { t } = useTranslation();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const handleSpreadsheetClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const { user, email } = useUserContext();
  if (user == null) {
    throw Error("user from userContext is null");
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      await UploadFile(user, email, file);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    window.location.reload();
  };

  return (
    <NavBarContainer>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ backgroundColor: "white" }}>
          <Toolbar>
            <LeftGroup>
              <Typography
                variant="h4"
                noWrap
                component="div"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  color: ADAPT_BLUE,
                  marginLeft: theme.spacing(3.5),
                }}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {t("navbar.title")}

                  <span
                    style={{
                      height: "10px",
                      width: "10px",
                      backgroundColor: "#F2BA29",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  ></span>
                  <Typography
                    noWrap
                    component="div"
                    style={{ textAlign: "left", color: "black" }}
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    {t("navbar.subTitle")}
                  </Typography>
                </Typography>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  accept=".csv"
                  onChange={handleFileChange}
                />
              </Typography>
              <Box sx={{ flexGrow: 0.2 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <StyledIconButton>
                  <ForumIcon color="disabled" />
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ color: "black" }}
                  >
                    {t("navbar.chat")}
                  </Typography>
                </StyledIconButton>
                <StyledIconButton onClick={handleSpreadsheetClick}>
                  <BorderAllIcon color="disabled" />
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ color: "black" }}
                  >
                    {t("navbar.spreadsheets")}
                    <input
                      accept="csv"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                    />
                  </Typography>
                </StyledIconButton>
                <StyledIconButton>
                  <AddBoxIcon color="disabled" />
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ color: "black" }}
                  >
                    {t("navbar.addAsset")}
                  </Typography>
                </StyledIconButton>
              </Box>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={t("navbar.searchBar")}
                  inputProps={{ "aria-label": t("navbar.searchBar") }}
                />
              </Search>
            </LeftGroup>
            <RightGroup>
              <Button onClick={handleLogout}>Logout</Button>
            </RightGroup>
          </Toolbar>
        </AppBar>
      </Box>
    </NavBarContainer>
  );
};
