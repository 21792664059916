export const convertCurrency = (
  amount: number,
  currency: string
): string | null => {
  if (amount === 0) return null;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amount);
};
