import { Components } from '@mui/material'
import palette from '../palette'

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    filled: {
      color: palette.formField?.label,
      transform: 'translate(20px, 14px) scale(1)',
      fontSize: '1rem',
      '&.Mui-focused': {
        color: palette.formField?.label,
      },
      '&.Mui-error': {
        color: palette.error?.main,
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(21px, 5px) scale(0.75)',
        maxWidth: 'calc(133% - 28px)',
      },
    },
  },
}

export default MuiInputLabel
