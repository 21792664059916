import { Components } from '@mui/material'
import { grey } from '@mui/material/colors'

const MuiTooltip: Components['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      marginTop: '0 !important',
      whiteSpace: 'pre-wrap',
      backgroundColor: grey[900],
      fontSize: '0.75rem',
      fontWeight: 400,
      padding: 8,
      wordBreak: 'break-word',
    },
  },
}

export default MuiTooltip
