import { alpha, Grid, styled, Typography } from "@mui/material";

export const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(1),
}));

export const StyledToolTip = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: alpha(theme.palette.common.black, 0.9),
  borderRadius: "8px",
  padding: "5px 10px",
  boxShadow: `0px 3px 6px 2px ${alpha(theme.palette.common.black, 0.1)}`,
}));

export const ColorSquare = styled("div")({
  width: "10px",
  height: "10px",
  borderRadius: "2px",
  marginRight: "5px",
});

export const TooltipText = styled("p")(({ theme }) => ({
  margin: theme.spacing(0, 0.5, 0, 0),
  fontSize: "14px",
}));

export const TooltipValue = styled("p")(({ theme }) => ({
  margin: 0,
  fontSize: "14px",
  marginLeft: "auto",
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0, 2, 2),
}));
