import { IconButton, PaletteColor, Typography } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Container, TextWrapper } from "./Chat.styels";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

interface ChatHeaderProps {
    onOpenChat: () => void;
    header: string;
    color: PaletteColor;
}
const onMenuClick = (e:
    React.MouseEvent<HTMLButtonElement, MouseEvent>
) => {
    e.stopPropagation();
}

export const ChatHeader = (props: ChatHeaderProps) => {
    return (
        <Container onClick={props.onOpenChat} colorKey={props.color.light}>
            <QuestionAnswerIcon style={{ color: props.color.dark }} />
            <TextWrapper >
                <Typography noWrap style={{ color: 'black' }}>
                    {props.header}
                </Typography>
            </TextWrapper>
            <IconButton size="small" aria-label="expand">
                <OpenInFullIcon fontSize="small" style={{ color: props.color.dark }} />
            </IconButton>
            <IconButton onClick={onMenuClick} size="small" aria-label="more">
                <MoreVertIcon fontSize="small" style={{ color: props.color.dark }} />
            </IconButton>
        </Container>
    );
}