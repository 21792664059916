import { alpha, Components } from '@mui/material'
import palette from '../palette'

const MuiAlert: Components['MuiAlert'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      padding: 8,
      justifyContent: 'center',
    },
    message: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    icon: {
      padding: 0,
      marginRight: 18,
      alignSelf: 'center',
    },
    outlinedError: {
      backgroundColor: alpha(palette.error.main, 0.1),
      borderColor: palette.error.main,
      color: palette.error.main,
    },
    outlinedWarning: {
      backgroundColor: alpha(palette.warning.main, 0.1),
      borderColor: palette.warning.main,
      color: palette.warning.main,
    },
    outlinedInfo: {
      backgroundColor: alpha(palette.extra.malibu.light, 0.1),
      borderColor: palette.extra.malibu.light,
      color: palette.primary.main,
    },
  },
}

export default MuiAlert
