import { Components, Theme, darken } from '@mui/material'

const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    sizeSmall: {
      height: 30,
    },
    root: ({ theme }) => ({
      textTransform: 'none',
      textAlign: 'center',
      boxShadow: theme.shadows[4],
    }),
    disableElevation: {
      boxShadow: 'none',
    },
    outlined: {
      borderRadius: 100,
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
      },
    },
    outlinedPrimary: ({ theme }) => ({
      borderColor: theme.palette.primary?.main,
    }),
    outlinedSecondary: ({ theme }) => ({
      borderColor: theme.palette.secondary?.main,
    }),
    contained: {
      borderRadius: 100,
    },
    containedInfo: ({ theme }) => ({
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
      '&:hover': {
        background: darken(theme.palette.background.default, 0.05),
      },
      '&:active': {
        background: darken(theme.palette.background.default, 0.05),
      },
    }),
    text: {
      fontWeight: 600,
      borderRadius: 100,
    },
  },
}

export default MuiButton
