import { Components } from '@mui/material'
import typography from '../typography'

const MuiLink: Components['MuiLink'] = {
  defaultProps: {
    variant: 'button',
    underline: 'none',
  },
  styleOverrides: {
    root: {
      fontWeight: typography.body1?.fontWeight,
      textTransform: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
}

export default MuiLink
