import { AccountTypes } from "./data";
//TEMP
// export const BASE_URL = 'https://snd4p7puf4.execute-api.us-east-1.amazonaws.com/dev';
export const BASE_URL =
  "https://dbi68nlua4.execute-api.us-east-1.amazonaws.com/dev/"; //sandbox
export const KPI_BASE_URL =
  "https://8co2thh7d9.execute-api.us-east-1.amazonaws.com";
export const CLIENT_ID =
  "404672213986-i4uu3rt1o7l1kqhhhq1ulp8k2s0espcn.apps.googleusercontent.com";
//export const KPI_BASE_URL = 'http://127.0.0.1:8000';
export const GET_PLAID_ACCOUNTS = "plaid/accounts/b2b-read";
export const PLAID_LINK_TOKEN = "plaid/auth/b2b-link-token";
export const EXCHANGE_PLAID_TOKEN = "plaid/auth/b2b-access-token";
export const UPLOAD_URL = "/assistant/generate-upload-url";
export enum ViewMode {
  Grid = "grid",
  List = "list",
}
export const USER_NOT_IN_WHITELIST = `Looks like your account is... nonexistent! contact us at  support@adapt-cfo.com and we’ll help you fix that in no time!`;
export const accountTypes: AccountTypes[] = [
  { type: "Bank Account", icon: "bank" },
  { type: "Card", icon: "card" },
  { type: "Crypto Account", icon: "crypto" },
  { type: "BNPL Account", icon: "bnpl" },
  { type: "Investments", icon: "investments" },
  { type: "Payment Apps", icon: "payment-apps" },
  { type: "Loans/ Debts", icon: "loans" },
];
