import { GoogleToken, ReactNodeProps } from "@/types/data";
import { CredentialResponse } from "@react-oauth/google";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";

interface UserContextValue {
    user: CredentialResponse | null;
    email: string;
    setUser: (user: CredentialResponse) => void;
}

const defaultValues: UserContextValue = {
    user: null,
    email: "",
    setUser: () => { },
};
export const UserContext = createContext<UserContextValue>(defaultValues);

export const UserContextProvider: React.FC<ReactNodeProps> = ({ children }) => {
    const [user, setUser] = useState<CredentialResponse | null>(() => {
        const storedUser = localStorage.getItem("accessToken");
        return storedUser ? JSON.parse(storedUser) : defaultValues.user;
    });
    const [email, setEmail] = useState<string>(defaultValues.email);

    useEffect(() => {
        if (user) {
            localStorage.setItem("accessToken", JSON.stringify(user));
            const decodedUser: GoogleToken = jwtDecode(user.credential ?? "");
            setEmail(decodedUser.email)
        } else {
            localStorage.removeItem("accessToken");
        }
    }, [user]);
    
    const contextValue: UserContextValue = useMemo(
        () => ({
            user: user,
            email: email,
            setUser: setUser,
        }),
        [user, email]
    )


    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
export const useUserContext = (): UserContextValue => useContext(UserContext)
