import { Grid, styled } from "@mui/material";

export const FlexLeft = styled(Grid)({
  flex: "0 1 50%",
  maxWidth: "50%",
});
export const FlexRight = styled(Grid)({
  flex: "1 0 50%",
  maxWidth: "50%",
  display: "flex",
  flexDirection: "row",
});

export const AppContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: theme.palette.extra.lightGray.main,
  width: "100%",
  boxSizing: "border-box",
  height: "calc(100vh - 60px)",
  paddingBottom: "2rem",
}));
