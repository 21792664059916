import { Mixins } from '@mui/material'

const mixins: Mixins = {
  toolbar: {
    minHeight: 60,
  },
  drawerContent: {
    minHeight: 'calc(100vh - 120px)',
  },
}

export default mixins
