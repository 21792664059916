import { Components } from '@mui/material'
import palette from '../palette'

const MuiFilledInput: Components['MuiFilledInput'] = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.formField?.border,
      borderRadius: 4,
      backgroundColor: palette.background?.paper,
      '& input::placeholder, & textarea::placeholder': {
        color: palette.formField?.placeholder,
        opacity: 1,
      },
      '& input:placeholder-shown': {
        textOverflow: 'ellipsis',
      },
      '&:hover': {
        backgroundColor: palette.background?.paper,
      },
      '&.Mui-focused': {
        backgroundColor: palette.background?.paper,
      },
      '&.Mui-error': {
        background: palette.formField?.errorBackground,
        borderColor: palette.error?.main,
      },
    },
    input: {
      padding: '21px 20px 5px',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      height: 'unset',
      color: palette.primary?.main,
    },
  },
}

export default MuiFilledInput
