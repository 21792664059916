import { useGetKpis } from "../hooks/useGetKpis";
import { AppContainer, FlexLeft, FlexRight } from "../App.styles";
import { ChatComponent } from "../components/Chat/Chat";
import { AdapHeader } from "../components/headers/adapt-header";
import placeholder from '../rightside.png';

export const Homepage = () => {
    const { kpis } = useGetKpis();

    return <AppContainer>
        <FlexLeft>
            <AdapHeader kpis={kpis}/>
            <ChatComponent />
        </FlexLeft>
        <FlexRight>
            <img src={placeholder} alt="placeholder" />
        </FlexRight>
    </AppContainer>
}