import { darken, lighten, Palette } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";

const defaultPalette = createPalette({});

const palette: Palette = {
  ...defaultPalette,
  mode: "light",
  background: {
    default: "#fff",
    paper: "#fff",
  },
  primary: {
    light: lighten("#003049", 0.1),
    main: "#003049",
    dark: darken("#003049", 0.1),
    contrastText: "#fff",
  },
  secondary: {
    light: "#05CEB3",
    main: "#0AAF96",
    dark: darken("#0AAF96", 0.1),
    contrastText: "#fff",
  },
  extra: {
    mintGreen: {
      light: lighten("#B8F5AE", 0.1),
      main: "#B8F5AE",
      dark: darken("#B8F5AE", 0.1),
      contrastText: "#fff",
    },
    lightRed: {
      light: lighten("#DA9191", 0.1),
      main: "#DA9191",
      dark: darken("#DA9191", 0.1),
      contrastText: "#fff",
    },
    mediumAquamarine: {
      light: lighten("#00D2B2", 0.1),
      main: "#00D2B2",
      dark: darken("#00D2B2", 0.1),
      contrastText: "#003049",
    },
    mistyLawn: {
      light: lighten("#DBFFE6", 0.1),
      main: "#DBFFE6",
      dark: darken("#DBFFE6", 0.1),
      contrastText: "#003049",
    },
    riceFlower: {
      light: lighten("#E4FFDD", 0.1),
      main: "#E4FFDD",
      dark: darken("#E4FFDD", 0.1),
      contrastText: "#003049",
    },
    wizardWhite: {
      light: lighten("#E1F2FB", 0.1),
      main: "#E1F2FB",
      dark: darken("#E1F2FB", 0.1),
      contrastText: "#003049",
    },
    freshWater: {
      light: lighten("#CBE6F8", 0.1),
      main: "#CBE6F8",
      dark: darken("#CBE6F8", 0.1),
      contrastText: "#003049",
    },
    heavenGates: {
      light: lighten("#C9EFFB", 0.1),
      main: "#C9EFFB",
      dark: darken("#C9EFFB", 0.1),
      contrastText: "#003049",
    },
    arabicaMint: {
      light: lighten("#BEFDF2", 0.1),
      main: "#BEFDF2",
      dark: darken("#BEFDF2", 0.1),
      contrastText: "#003049",
    },
    mintTea: {
      light: lighten("#AEEAE2", 0.1),
      main: "#AEEAE2",
      dark: darken("#AEEAE2", 0.1),
      contrastText: "#003049",
    },
    peacefulRain: {
      light: lighten("#F3F9F2", 0.1),
      main: "#F3F9F2",
      dark: darken("#F3F9F2", 0.1),
      contrastText: "#003049",
    },
    contrail: {
      light: lighten("#D2CAF4", 0.1),
      main: "#D2CAF4",
      dark: darken("#D2CAF4", 0.1),
      contrastText: "#003049",
    },
    mintyRose: {
      light: lighten("#FFE2E2", 0.1),
      main: "#FFE2E2",
      dark: darken("#FFE2E2", 0.1),
      contrastText: "#003049",
    },
    templeOfOrange: {
      light: lighten("#EC7A58", 0.1),
      main: "#EC7A58",
      dark: darken("#EC7A58", 0.1),
      contrastText: "#673222",
    },
    hailstorm: {
      light: lighten("#CFCFE1", 0.1),
      main: "#CFCFE1",
      dark: darken("#CFCFE1", 0.1),
      contrastText: "#003049",
    },
    architectureBlue: {
      light: lighten("#7594A4", 0.1),
      main: "#7594A4",
      dark: darken("#7594A4", 0.1),
      contrastText: "#fff",
    },
    coastalBreeze: {
      light: lighten("#DDF7F9", 0.1),
      main: "#DDF7F9",
      dark: darken("#DDF7F9", 0.1),
      contrastText: "#003049",
    },
    malibu: {
      light: lighten("#77D6F4", 0.1),
      main: "#77D6F4",
      dark: darken("#77D6F4", 0.1),
      contrastText: "#003049",
    },
    generic: {
      light: "#DADFE1",
      main: "#D2D3D3",
      dark: "#C1C1C1",
      contrastText: "#003049",
    },
    limeade: {
      light: lighten("#30B404", 0.1),
      main: "#30B404",
      dark: darken("#30B404", 0.1),
      contrastText: "#fff",
    },
    foam: {
      light: lighten("#d6f3fc", 0.1),
      main: "#d6f3fc",
      dark: darken("#d6f3fc", 0.1),
      contrastText: "#003049",
    },
    porcelain: {
      light: lighten("#F5F7F8", 0.1),
      main: "#F5F7F8",
      dark: darken("#F5F7F8", 0.1),
      contrastText: "#003049",
    },
    silverFoil: {
      light: lighten("#B4AFAF", 0.1),
      main: "#B4AFAF",
      dark: darken("#B4AFAF", 0.1),
      contrastText: "#003049",
    },
    salmonPink: {
      light: lighten("#FF9E9E", 0.1),
      main: "#FF9E9E",
      dark: darken("#FF9E9E", 0.1),
      contrastText: "#003049",
    },
    blackPearl: {
      light: lighten("#07202D", 0.1),
      main: "#07202D",
      dark: darken("#07202D", 0.1),
      contrastText: "#fff",
    },
    alabaster: {
      light: lighten("#FAFAFA", 0.1),
      main: "#FAFAFA",
      dark: darken("#FAFAFA", 0.1),
      contrastText: "#003049",
    },
    silverChalice: {
      light: lighten("#AAAAAA", 0.1),
      main: "#AAAAAA",
      dark: darken("#AAAAAA", 0.1),
      contrastText: "#fff",
    },
    lightSilverChalice: {
      light: lighten("#F5F6F6", 0.1),
      main: "#F5F6F6",
      dark: darken("#F5F6F6", 0.1),
      contrastText: "#fff",
    },
    niagara: {
      light: lighten("#0D838C", 0.1),
      main: "#0D838C",
      dark: darken("#0D838C", 0.1),
      contrastText: "#fff",
    },
    tropicalBlue: {
      light: lighten("#CBE6F8", 0.1),
      main: "#CBE6F8",
      dark: darken("#CBE6F8", 0.1),
      contrastText: "#003049",
    },
    seaShell: {
      light: lighten("#F1F1F1", 0.1),
      main: "#F1F1F1",
      dark: darken("##F1F1F1", 0.1),
      contrastText: "#003049",
    },
    white: {
      light: lighten("#fff", 0.1),
      main: "#fff",
      dark: darken("#fff", 0.1),
      contrastText: "#003049",
    },
    mineShaft: {
      light: lighten("#282828", 0.1),
      main: "#282828",
      dark: darken("#282828", 0.1),
      contrastText: "#fff",
    },
    mercury: {
      light: lighten("#E9E9E9", 0.1),
      main: "#E9E9E9",
      dark: darken("#E9E9E9", 0.1),
      contrastText: "#003049",
    },
    dustySand: {
      light: lighten("#FFEFC6", 0.1),
      main: "#FFEFC6",
      dark: darken("#FFEFC6", 0.1),
      contrastText: "#FFEFC6",
    },
    lightLime: {
      light: lighten("#BEFFCD", 0.1),
      main: "#BEFFCD",
      dark: darken("#BEFFCD", 0.1),
      contrastText: "#BEFFCD",
    },
    pastelMint: {
      light: lighten("#E1F8F6", 0.1),
      main: "#C8F3EC",
      dark: darken("#C8F3EC", 0.1),
      contrastText: "#C8F3EC",
    },
    securityRed: {
      light: lighten("#EC5858", 0.1),
      main: "#EC5858",
      dark: darken("#EC5858", 0.1),
      contrastText: "#EC5858",
    },
    lightGray: {
      light: lighten("#ECEFF1", 0.1),
      main: "#ECEFF1",
      dark: darken("#ECEFF1", 0.1),
      contrastText: "#fff",
    },
    adaptBlue: {
      light: lighten("#04AEAE", 0.1),
      main: "#04AEAE",
      dark: darken("#04AEAE", 0.1),
      contrastText: "#fff",
    },
    adaptOrange: {
      light: lighten("#F2BA29", 0.1),
      main: "#F2BA29",
      dark: darken("#F2BA29", 0.1),
      contrastText: "#fff",
    },
  },
  formField: {
    border: "#B8C5CC",
    label: "#003049",
    errorBackground: "#FFF5F5",
    placeholder: "#AAAAAA",
  },
  text: {
    primary: "#000",
    secondary: "#C1C1C1",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
  error: {
    dark: darken("#F93232", 0.1),
    main: "#F93232",
    light: lighten("#F93232", 0.1),
    contrastText: "#fff",
  },
  warning: {
    contrastText: "#fff",
    light: lighten("#EC7A58", 0.1),
    main: "#EC7A58",
    dark: darken("#EC7A58", 0.1),
  },
  success: {
    dark: darken("#74DC6B", 0.1),
    main: "#74DC6B",
    light: lighten("#74DC6B", 0.1),
    contrastText: "#003049",
  },
};

export default palette;
