import { Components, darken } from '@mui/material'
import palette from '../palette'

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      margin: '6px 0px 0px',
      color: darken(palette.extra?.generic.main as string, 0.2),
      '&.Mui-error': {
        color: darken(palette.extra?.generic.main as string, 0.2),
      },
    },
  },
}

export default MuiFormHelperText
