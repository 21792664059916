import { Grid, styled } from "@mui/material";

export const Container = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.extra.white.main,
  borderRadius: 8,
  height: theme.spacing(11.2),
  width: theme.spacing(25),
  margin: theme.spacing(5, 25, 0, 0),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1rem",
  flexDirection: "row",
  color: theme.palette.extra.lightGray.dark,
  ":hover": {
    transition: "0.3s",
    boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
  },
}));

export const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));
