import { Grid, styled, Typography } from "@mui/material";

export const Item = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: 12,
  padding: theme.spacing(1, 2, 0, 2),
  wordWrap: "break-word",
  maxWidth: theme.spacing(19),
}));

export const CustomTypographyValue = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  justifyContent: "center",
}));

export const Header = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
}));

export const ValueWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: theme.spacing(2, 2),
}));

interface StyledGridProps {
  backgroundColor?: string;
}

export const StyledGrid = styled(Grid)<StyledGridProps>(
  ({ theme, backgroundColor }) => ({
    padding: theme.spacing(1),
    marginRight: theme.spacing(6),
    backgroundColor: backgroundColor || theme.palette.extra.adaptBlue.main,
    borderRadius: 8,
    height: "fit-content",
    minWidth: theme.spacing(20),
    "&:hover": {
      cursor: "pointer",
    },
  })
);
