import { Components } from '@mui/material'

const MuiPaper: Components['MuiPaper'] = {
  styleOverrides: {
    rounded: {
      borderRadius: 8,
    },
  },
}

export default MuiPaper
