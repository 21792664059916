import { Components, Theme } from '@mui/material'
import MuiAlert from './MuiAlert'
import MuiAlertTitle from './MuiAlertTitle'
import MuiBackdrop from './MuiBackdrop'
import MuiBreadcrumbs from './MuiBreadcrumbs'
import MuiButton from './MuiButton'
import MuiCard from './MuiCard'
import MuiCssBaseline from './MuiCssBaseline'
import MuiFilledInput from './MuiFilledInput'
import MuiFormControl from './MuiFormControl'
import MuiFormHelperText from './MuiFormHelperText'
import MuiIconButton from './MuiIconButton'
import MuiInputLabel from './MuiInputLabel'
import MuiLink from './MuiLink'
import MuiMenu from './MuiMenu'
import MuiMenuItem from './MuiMenuItem'
import MuiPaper from './MuiPaper'
import MuiRadio from './MuiRadio'
import MuiSelect from './MuiSelect'
import MuiTextField from './MuiTextField'
import MuiToolbar from './MuiToolbar'
import MuiTooltip from './MuiTooltip'

const components: Components<Theme> = {
  MuiCssBaseline,
  MuiButton,
  MuiPaper,
  MuiCard,
  MuiToolbar,
  MuiLink,
  MuiBreadcrumbs,
  MuiTextField,
  MuiFilledInput,
  MuiInputLabel,
  MuiFormControl,
  MuiBackdrop,
  MuiFormHelperText,
  MuiSelect,
  MuiMenuItem,
  MuiMenu,
  MuiRadio,
  MuiTooltip,
  MuiAlert,
  MuiAlertTitle,
  MuiIconButton,
  // <-- components-end -->
}

export default components
