import { Container, styled } from "@mui/material";

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  flexDirection: "column",
}));

export const LogoWrapper = styled("div")(({ theme }) => ({
  flexDirection: "row",
  marginBottom: theme.spacing(3),
}));
