import { Components } from '@mui/material'
import palette from '../palette'

const MuiBreadcrumbs: Components['MuiBreadcrumbs'] = {
  defaultProps: {
    separator: '>',
  },
  styleOverrides: {
    separator: {
      margin: '0 8px',
      color: palette.primary?.main,
      fontWeight: 400,
    },
    li: {
      '& span, a': {
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
      },
    },
  },
}

export default MuiBreadcrumbs
