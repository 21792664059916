import { Components } from '@mui/material'

const MuiAlertTitle: Components['MuiAlertTitle'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontWeight: 600,
      marginTop: 0,
      marginBottom: 0,
      lineHeight: 'normal',
    },
  },
}

export default MuiAlertTitle
