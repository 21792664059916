import React, { useContext, useMemo, useState } from 'react';
import { createContext } from "react";
import { PalidContextValue, ReactNodeProps } from "../types/data";
import { ViewMode } from '../types/constanst';

const defaultValues: PalidContextValue = {
    search: {
        set: () => { },
        value: [],
    },
    viewMode: {
        set: () => { },
        value: ViewMode.List,
    }
}

export const FilterContext = createContext<PalidContextValue>(defaultValues);

export const FilterContextProvider: React.FC<ReactNodeProps> = ({ children }) => {

    const [search, setSearch] = useState<string[]>([])
    const [viewMode, setViewMode] = useState(defaultValues.viewMode.value)

    const contextValue: PalidContextValue = useMemo(
        () => ({
            search: {
                set: setSearch,
                value: search,
            },
            viewMode: {
                set: setViewMode,
                value: viewMode,
            }
        }),
        [search, viewMode]
    )


    return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};
export const useFilterContext = (): PalidContextValue => useContext(FilterContext)
