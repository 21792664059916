import { CredentialResponse } from "@react-oauth/google";
import { useUserContext } from "../../context/user-context";
import { BASE_URL, UPLOAD_URL } from "../../types/constanst";

interface UploadResponse {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    "x-amz-security-token": string;
    policy: string;
    signature: string;
  };
}

const uploadFileToS3 = (s3Bucket: UploadResponse, file: File) => {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", s3Bucket.url, true);

  const formData = new FormData();
  Object.entries(s3Bucket.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("file", file);

  xhr.onload = () => {
    if (xhr.status === 204) {
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  xhr.send(formData);
};

export const UploadFile = async (
  user: CredentialResponse,
  email: string,
  file: File
) => {
  const bucketData = await fetch(`${BASE_URL}${UPLOAD_URL}`, {
    method: "POST",
    headers: {
      "manteca-authorization": `Bearer ${user?.credential}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_email: email,
    }),
  });

  const s3Bucket: UploadResponse = await bucketData.json();

  uploadFileToS3(s3Bucket, file);
};
