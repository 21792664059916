import { createBreakpoints, BreakpointsOptions } from '@mui/system'

const breakpointOptions: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1320,
    xl: 1536,
  },
}

export default createBreakpoints(breakpointOptions)
