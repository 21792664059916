import { Components } from '@mui/material'
import palette from '../palette'

const MuiMenu: Components['MuiMenu'] = {
  styleOverrides: {
    list: {
      backgroundColor: palette?.grey ? palette.grey[100] : '',
    },
  },
}

export default MuiMenu
