import { Typography } from '@mui/material/styles/createTypography'

const fontFamily = [
  'Poppins',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',')

const typography: Partial<Typography> = {
  fontFamily,
  fontWeightLight: 400,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    fontSize: '3.5rem',
    fontStyle: 'normal',
    lineHeight: '110%',
  },
  h2: {
    fontWeight: 700,
    fontSize: '3rem',
    fontStyle: 'normal',
    lineHeight: '120%',
  },
  h3: {
    fontWeight: 700,
    fontSize: '2rem',
    fontStyle: 'normal',
    lineHeight: '120%',
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.25rem',
    fontStyle: 'normal',
    lineHeight: '120%',
  },
  h5: {
    fontWeight: 500,
    fontSize: '0.875rem',
    fontStyle: 'normal',
    lineHeight: '120%',
  },
  h6: {
    fontWeight: 500,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    lineHeight: '120%',
  },
  body1: {
    fontWeight: 400,
    fontSize: '0.875rem',
    fontStyle: 'normal',
    lineHeight: '140%',
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    lineHeight: '150%',
  },
  button: {
    fontWeight: 600,
    fontSize: '0.875rem',
    fontStyle: 'normal',
    textTransform: 'none',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.625rem',
    fontStyle: 'normal',
    lineHeight: '150%',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '1rem',
    fontStyle: 'normal',
  },
}

export default typography
