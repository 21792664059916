import { Components } from '@mui/material'
import palette from '../palette'

const MuiSelect: Components['MuiSelect'] = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    icon: {
      transition: 'transform .3s',
      color: palette.primary?.main,
      right: 20,
    },
    select: {
      '&:first-of-type': {
        paddingRight: 56,
      },
    },
  },
}

export default MuiSelect
