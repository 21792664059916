import { Components, Theme } from '@mui/material'

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.extra.silverChalice.main,
    }),
    colorPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
    }),
    colorSecondary: ({ theme }) => ({
      color: theme.palette.secondary.main,
    }),
    colorInherit: {
      color: 'inherit',
    },
  },
}

export default MuiIconButton
