import { useTranslation } from "react-i18next";
import { Container, ContentWrapper } from "./CreateNewKpiButton.styles"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const CreateNewKpiButton = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <ContentWrapper>
                <AddCircleOutlineIcon fontSize="large" />
                <span>{t('kpis.createNewKpi')}</span>
            </ContentWrapper>
        </Container>
    )
}