import { alpha, Components } from '@mui/material'
import palette from '../palette'

const MuiBackdrop: Components['MuiBackdrop'] = {
  styleOverrides: {
    root: {
      background: alpha(palette.primary?.main || '', 0.075),
    },
  },
}

export default MuiBackdrop
