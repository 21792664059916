import { Navbar } from "../components/navbar/navbar";

function Layout({ children, needNavbar = true }) {
  return (
    <div>
      {needNavbar ? <Navbar /> : <span></span>}
      <div className="content">{children}</div>
    </div>
  );
}

export default Layout;
