import { Chat } from "@adapt-cfo/adapt-chat";
import React from "react";
import { ParentContainer } from "./Chat.styels";
import { useUserContext } from "../../context/user-context";

// const chats = [
//     { header: "Whats my status on the loan?", },
//     { header: "How much did the i earn last quartar?", }
// ]

export const ChatComponent = () => {
  const { user, email } = useUserContext();

  return (
    <ParentContainer>
      <Chat
        title={"Start a conversation"}
        token={user?.credential ?? ""}
        handleClose={() => {
          window.location.reload();
        }}
        userEmail={email}
        chatId={undefined}
      />
    </ParentContainer>
  );

  // const [openedChatIndex, setOpenedChatIndex] = useState<number | null>(null);
  // const [isNewChatOpen, setIsNewChatOpen] = useState<boolean>(false);
  // const { chats } = useGetUserChats(USER_ID);

  // if (!chats?.chats_started.length && !isNewChatOpen) return <ChatWrapper>
  //     <ChatHeader
  //         onOpenChat={() => setIsNewChatOpen(true)}
  //         header={'test'}
  //         color={colors[1 % colors.length]} />
  // </ChatWrapper >

  // if (isNewChatOpen) return <StyledContainer isConversationStarted={isNewChatOpen}>

  //     <Popup
  //         onClose={() => setOpenedChatIndex(null)}
  //         show={isNewChatOpen}
  //     >
  //         <Chat
  //             title={'Start a conversation'}
  //             token={token}
  //             handleClose={() => setIsNewChatOpen(false)}
  //             userId={USER_ID}
  //             chatId={undefined}
  //         />
  //     </Popup>
  // </StyledContainer>

  // return (
  //     <ParentContainer>
  //         {chats?.chats_started.map((chat, index) => (
  //             <React.Fragment key={index}>
  //                 {openedChatIndex === index && <Overlay />}
  //                 <StyledContainer isConversationStarted={openedChatIndex === index}>
  //                     <Popup
  //                         onClose={() => setOpenedChatIndex(null)}
  //                         show={openedChatIndex === index}
  //                     >
  //                         <Chat
  //                             title={chat}
  //                             token={token}
  //                             handleClose={() => setOpenedChatIndex(null)}
  //                             userId={USER_ID}
  //                             chatId={chat}
  //                         />
  //                     </Popup>
  //                     <ChatWrapper>
  //                         {openedChatIndex !== index && (
  //                             <ChatHeader
  //                                 onOpenChat={() => setOpenedChatIndex(index)}
  //                                 header={chat}
  //                                 color={colors[index % colors.length]} />
  //                         )}
  //                         {/* {openedChatIndex === index && (

  //                         )} */}
  //                     </ChatWrapper>
  //                 </StyledContainer>
  //             </React.Fragment>
  //         ))}
  //     </ParentContainer>
  // );
};
