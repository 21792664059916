import { Shadows } from '@mui/material/styles/shadows'

const shadows: Shadows = [
  'none',
  '2px 4px 12px rgba(0, 0, 0, 0.05)',
  '0px 8px 24px rgba(0, 0, 0, 0.05)',
  '0px 8px 24px rgba(0, 0, 0, 0.08)',
  '0px 4px 10px rgba(0, 0, 0, 0.1)',
  '0px 4px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
  '0px 8px 24px rgba(0, 0, 0, 0.15)',
]

export default shadows
