import { LabelList, Pie, PieChart, Tooltip } from "recharts"
import { ColorSquare, StyledGrid, StyledToolTip, TooltipText, TooltipValue } from "./ChartStyles"
import { assets } from "../../hooks/dummy-assets";
import theme from '../../../src/theme'
import { useMemo } from "react";

const shuffleWithSeed = (array: any[], seed: number) => {
    let m = array.length, t, i;

    while (m) {
        i = Math.floor(random(seed) * m--);

        t = array[m];
        array[m] = array[i];
        array[i] = t;

        ++seed;
    }

    return array;
};

const random = (seed: number) => {
    const x = Math.sin(seed) * 100;
    return x - Math.floor(x);
};

export const AssetPieChart = () => {
    const colors = useMemo(() => {
        const colorValues = Object.values(theme.palette.extra);
        return shuffleWithSeed(colorValues, 123);
    }, []);
    const assetsWithColors = assets.map((asset, index) => ({
        ...asset,
        fill: colors[index % colors.length].main,
    }));

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <StyledToolTip style={{ backgroundColor: theme.palette.extra.white.main, padding: theme.spacing(1) }}>
                    <ColorSquare style={{ backgroundColor: payload[0].payload.fill }} />
                    <TooltipText>{payload[0].name}</TooltipText>
                    <TooltipValue>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(payload[0].value)}</TooltipValue>
                </StyledToolTip>
            );
        }

        return null;
    };

    const formatAsPercentage = (value: number) => {
        const totalBalance = assets.reduce((sum, asset) => sum + asset.balance, 0);
        return `${((value / totalBalance) * 100).toFixed(2)}%`;
    };
    return (
        <StyledGrid>
            <PieChart width={280} height={250}>
                <Pie
                    data={assetsWithColors}
                    dataKey="balance"
                    nameKey="financialCategory"
                    innerRadius={60}
                    strokeWidth={5}
                    label={false}
                >
                    <LabelList
                        fill="black"
                        dataKey="balance"
                        formatter={formatAsPercentage}
                        stroke="none"
                    />
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </StyledGrid>
    )
}
