import { useUserContext } from "../../context/user-context";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import { LogoWrapper, StyledContainer } from "./GoogleLogin.styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

export const ADAPT_BLUE = '#06AEAE'

export const GoogleLoginWrapper = () => {
    const { t } = useTranslation();
    const { setUser } = useUserContext()
    const responseMessage = (response: CredentialResponse) => {
        setUser(response);
    };
    const errorMessage = (error: Error) => {
        console.log(error);
    };
    return (
        <StyledContainer>
            <Typography
                variant="h4"
                noWrap
                component="div"
                style={{
                    fontWeight: 'bold', color: ADAPT_BLUE
                }}
                sx={{ display: { xs: 'none', sm: 'block' } }}
            >
                <LogoWrapper>
                    {t('navbar.title')}
                    <span style={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#F2BA29',
                        borderRadius: '50%',
                        display: 'inline-block',
                    }}></span>
                </LogoWrapper>
            </Typography>
            <GoogleLogin
                onSuccess={responseMessage}
                onError={() => errorMessage}
                theme="filled_blue"
                size="large"
                text="signin_with"
                shape="pill"
            />
        </StyledContainer>
    )
}