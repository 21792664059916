import { Components } from '@mui/material'
import palette from '../palette'

const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: {
    html: {
      scrollBehavior: 'smooth',
      height: '100%',
    },
    'body, #root': {
      height: '100%',
    },
    '::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '::-webkit-scrollbar-track': {
      marginBlock: 53,
      borderRadius: 10,
      background: 'rgba(0, 0, 0, 0.06)',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: palette.primary?.main,
    },
  },
}

export default MuiCssBaseline
