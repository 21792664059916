export const assets = [
  {
    name: "Dummy Account",
    type: "card",
    balance: 1000,
    id: "dummy-id-123",
    financialCategory: "Banking",
    isConnected: true,
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Toronto-Dominion_Bank_logo.svg/400px-Toronto-Dominion_Bank_logo.svg.png",
    label: "UBS Presonal",
    accountNumber: "98698955",
  },
  {
    name: "Dummy Account",
    type: "bank",
    balance: 3500,
    id: "dummy-id-1234",
    financialCategory: "Savings",
    isConnected: false,
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Toronto-Dominion_Bank_logo.svg/400px-Toronto-Dominion_Bank_logo.svg.png",
    label: "Cointbase wallet",
    accountNumber: "234567",
  },
  {
    name: "Dummy Account",
    type: "bank",
    balance: 3500,
    id: "dummy-id-1234-567",
    financialCategory: "Investment",
    isConnected: false,
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Toronto-Dominion_Bank_logo.svg/400px-Toronto-Dominion_Bank_logo.svg.png",
    label: "Cointbase wallet",
    accountNumber: "234567876",
  },
  {
    name: "Dummy Account",
    type: "crypto",
    balance: 2200,
    id: "dummy-id-1234-9564",
    financialCategory: "Crypto",
    isConnected: false,
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Toronto-Dominion_Bank_logo.svg/400px-Toronto-Dominion_Bank_logo.svg.png",
    label: "Cointbase wallet",
    accountNumber: "452169959",
  },
];
