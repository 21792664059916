import { Typography } from "@mui/material";
import { CustomTypography, CustomTypographyValue, Header, StyledGrid } from "./InsightCard.styles";
import { SvgIconComponent } from "@mui/icons-material";

interface ExtendedInsightCardProps {
    title: string;
    date: string;
    value: string;
    color: string;
    Icon: SvgIconComponent;
}

export const ExtentedInsightCard = ({ title, color, Icon, value, date }: ExtendedInsightCardProps) => {
    return (
        <StyledGrid backgroundColor={color}>
            <Header>
                <Icon color='disabled' />
                <Typography variant="h5">{date}</Typography>
            </Header>
            <CustomTypography variant="h5">{title}</CustomTypography>
            <CustomTypographyValue variant="h5">{value}</CustomTypographyValue>
        </StyledGrid>
    );
};