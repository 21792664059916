import LuggageIcon from '@mui/icons-material/Luggage';
import { Container, Item, StyledGrid, ValueWrapper } from "./FinanceOverviewKpi.styles"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { convertCurrency } from '../utils/convertCurrency';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Payment } from '@mui/icons-material';

export const FinanceOverviewKpi = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <StyledGrid>
                <Item>
                    <LuggageIcon color='disabled' />
                    <Typography variant="h5">{t('kpis.loansAndDepts')}</Typography>
                </Item>
                <ValueWrapper>
                    <Typography variant="h5" >{convertCurrency(1234, "USD")}</Typography>
                </ValueWrapper>
            </StyledGrid>
            <StyledGrid>
                <Item>
                    <LocalFireDepartmentIcon color='disabled' />
                    <Typography variant="h5">{t('kpis.burnRate')}</Typography>
                </Item>
                <ValueWrapper>
                    <Typography variant="h5" >{convertCurrency(1234, "USD")}</Typography>
                </ValueWrapper>
            </StyledGrid>
            <StyledGrid>
                <Item>
                    <ReceiptIcon color='disabled' />
                    <Typography variant="h5">{t('kpis.payroll')}</Typography>
                </Item>
                <ValueWrapper>
                    <Typography variant="h5" >{convertCurrency(1234, "USD")}</Typography>
                </ValueWrapper>
            </StyledGrid>
            <StyledGrid>
                <Item>
                    <Payment color='disabled' />
                    <Typography variant="h5">{t('kpis.monthlyBudget')}</Typography>
                </Item>
                <ValueWrapper>
                    <Typography variant="h5" >{convertCurrency(1234, "USD")}</Typography>
                </ValueWrapper>
            </StyledGrid>
        </Container>
    )
}