import { Wallet } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { Container } from './adapt-header.styles';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { convertCurrency } from '../../utils/convertCurrency';
import { Kpi } from '../../types/data';
import { InsightCard } from '../InsightCard/InsightCard';

interface AdapHeaderProps {
    kpis: Kpi
}
export const AdapHeader: React.FC<AdapHeaderProps> = ({ kpis }) => {
    const { t } = useTranslation();
    const { overall_balance, income, expenses } = kpis;
    return (
        <Container>
            <InsightCard
                title={t('header.overallBalance')}
                value={convertCurrency(overall_balance, 'USD') || 'loading...'}
                Icon={Wallet}
                color={theme.palette.extra.adaptBlue.main} />
            <InsightCard
                title={t('header.income')}
                value={convertCurrency(income, 'USD') || 'loading...'}
                Icon={LoginIcon}
                color={theme.palette.extra.adaptBlue.main} />
            <InsightCard
                title={t('header.expenses')}
                value={convertCurrency(expenses, 'USD') || 'loading...'}
                Icon={LogoutIcon}
                color={theme.palette.extra.adaptOrange.main} />
        </Container >
    );
}