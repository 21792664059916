import { Components, alpha } from '@mui/material'
import palette from '../palette'

const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      '&.Mui-selected[aria-selected="true"]': {
        backgroundColor: alpha(palette.secondary?.main || '', 0.1),
      },
    },
  },
}

export default MuiMenuItem
