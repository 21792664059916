import { styled, Grid } from "@mui/material";

export const ParentContainer = styled(Grid)({
  marginTop: "2.5rem",
  marginLeft: "2.5rem",
  height: "60vh",
});

export const StyledContainer = styled(Grid)<{ isConversationStarted: boolean }>(
  ({ isConversationStarted }) => ({
    paddingBottom: "1rem",
    position: "relative",
    ...(isConversationStarted && {
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    }),
  })
);

export const ChatWrapper = styled(Grid)({
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  // backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 999,
});

export const Container = styled(Grid)<{ colorKey: string }>(
  ({ theme, colorKey }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: colorKey,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: 5,
    width: theme.spacing(50),
    cursor: "pointer",
    height: theme.spacing(5),
  })
);

export const TextWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(1),
  marginRight: "auto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
