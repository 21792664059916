import { ChatHeader } from "../components/Chat/ChatHeader";
import { FinanceOverviewKpi } from "../FinanceOverviewKpi/FinanceOverviewKpi";
import { CreateNewKpiButton } from "../components/CreateNewKpiButton/CreateNewKpiButton";
import { AdapHeader } from "../components/headers/adapt-header";
import { useGetKpis } from "../hooks/useGetKpis";
import { BarChartWrapper, ChartWrapper, LeftGroup, FlexRow, InsightCardWrapper, KpiContainer, PopupWrapper, RightGroup } from "./SharedStyles";
import theme from "../theme";
import CustomLineChart from "../components/Charts/CustomLineChart";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { ExtentedInsightCard } from "../components/InsightCard/ExtendedInsightCard";
import CustomBarChart from "../components/Charts/CustomBarChart";
import { AssetPieChart } from "../components/Charts/AssetPieChart";
import SingleLineChart from "../components/Charts/SingleLineChart";

export const Kpis = () => {
    const { kpis } = useGetKpis();
    const date = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'short', });
    return (<KpiContainer>
        <LeftGroup>
            <FlexRow>
                <AdapHeader kpis={kpis} />
                <CreateNewKpiButton />
            </FlexRow>
            <FlexRow>
                <FinanceOverviewKpi />
                <PopupWrapper>
                    <ChatHeader color={theme.palette.extra.tropicalBlue} header="Marketing campaign budget" onOpenChat={() => console.log("open")} />
                    <ChatHeader color={theme.palette.extra.tropicalBlue} header="Office supplies budget" onOpenChat={() => console.log("open")} />
                    <ChatHeader color={theme.palette.extra.tropicalBlue} header="Back to school campaigns" onOpenChat={() => console.log("open")} />
                    <BarChartWrapper>
                        <CustomBarChart />
                    </BarChartWrapper>
                </PopupWrapper>
            </FlexRow>
            <FlexRow>
                <ChartWrapper>
                    <CustomLineChart />
                </ChartWrapper>
                <InsightCardWrapper>
                    <ExtentedInsightCard title="Marketing Campaign Budget" date={date} value="1,000,000" color={theme.palette.extra.tropicalBlue.main} Icon={TrackChangesIcon} />
                    <ExtentedInsightCard title="Office Supplies Budget" date={date} value="4,000,000" color={theme.palette.extra.tropicalBlue.main} Icon={TrackChangesIcon} />
                </InsightCardWrapper>
            </FlexRow>
        </LeftGroup>
        <RightGroup>
            <AssetPieChart />
            <SingleLineChart />
        </RightGroup>
    </KpiContainer>)
}