import { Grid, styled } from "@mui/material";

export const Container = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.extra.white.main,
  borderRadius: 8,
  height: "fit-content",
  width: theme.spacing(72),
  margin: theme.spacing(5, 0, 0, 5),
  display: "flex",
  fontSize: "1rem",
  flexWrap: "wrap",
}));

export const Item = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2, 0, 0, 2),
  "& svg": {
    marginTop: "-5px",
  },
}));

export const ValueWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2, 1),
  flexDirection: "row",
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));
