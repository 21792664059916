import { createBrowserRouter } from "react-router-dom";
import { Homepage } from "./Homepage";
import { Kpis } from "./Kpis";
import Layout from "../layout/Layout";
export const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <Layout>
        <Homepage />
      </Layout>
    ),
  },
  {
    path: "/home",
    element: (
      <Layout>
        <Homepage />
      </Layout>
    ),
  },
  {
    path: "/kpis",
    element: (
      <Layout>
        <Kpis />
      </Layout>
    ),
  },
]);
