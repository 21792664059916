import { Components } from '@mui/material'
import palette from '../palette'

const MuiRadio: Components['MuiRadio'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      '& svg:first-of-type': {
        width: 20,
        height: 20,
      },
      '& svg:last-of-type': {
        marginTop: -2,
        marginLeft: -2,
      },
    },
    colorPrimary: {
      '& svg:first-of-type': {
        color: palette.extra?.generic?.light,
      },
      '&.Mui-checked svg:last-of-type': {
        color: palette.success?.main,
      },
    },
  },
}

export default MuiRadio
