import { Item, StyledGrid, ValueWrapper } from "./InsightCard.styles";
import { Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";


interface InsightCardProps {
    title: string;
    value: string;
    color: string;
    Icon: SvgIconComponent;
}
export const InsightCard = ({ title, color, Icon, value }: InsightCardProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleClick = () => {
        if (location.pathname === '/kpis') return;
        navigate('/kpis');
    };
    return (
        <StyledGrid backgroundColor={color} onClick={handleClick}>
            <Item>
                <Icon color='disabled' />
                <Typography variant="h5">{title}</Typography>
            </Item>
            <ValueWrapper>
                <Typography variant="h5">{value}</Typography>
            </ValueWrapper>
        </StyledGrid>
    );
};