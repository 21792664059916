import { styled } from "@mui/material";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "row",
  alignItems: "flex-start",
  borderRadius: 8,
  justifyContent: "flex-start",
  margin: theme.spacing(5, 0, 0, 5),
}));
