import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { CustomTypography } from './ChartStyles';

const data = [
    {
        name: 'Q1 23',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Q2 23',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Q3 23',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Q4 23',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Q1 24',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Q2 24',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Q3 24',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export default class CustomLineChart extends PureComponent {
    render() {
        return (
            <>
                <CustomTypography variant="h5">Line Chart</CustomTypography>
                <LineChart
                    style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                    width={500}
                    height={300}
                    data={data}
                >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
            </>
        );
    }
}